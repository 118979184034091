<template>
  <v-list>
    <!-- <v-subheader class="font-weight-bold grey--text"> Contacts </v-subheader> -->
    <v-list-item-group v-model="item" color="primary">
      <v-list-item
        v-for="(item, index) in patientData"
        :key="index"
        @click="contactView(item)"
        class="mb-3"
      >
        <v-list-item-avatar>
          <v-img :src="item.avatar" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
          <v-list-item-subtitle v-html="item.status"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon class="align-self-center">
          <v-icon class="body-1" :color="item.active ? 'success' : 'grey'">
            mdi-record
          </v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
<script>
import { mapActions } from "vuex";

export default {
  components: {
    // SelectBoxClinic,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      item: -1,
      isLoading: false,
      // chats: [],
    };
  },
  methods: {
    contactView(item) {
      let contactID = contact.id;
      // axios.get("/api/chat/id", { contactID }).then((response) => {
      //   console.log(response.data.chats);
      //   this.singleChatInfo = response.data.chats;
      //   this.setSingleChatInfo(this.singleChatInfo);
      // });
    },
    ...mapActions(["setSingleChatInfo"]),
  },
};
</script>
<style lang="scss">
.clinicBox {
  padding: 0 0 0 20px;
}
</style>