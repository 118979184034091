<template>
  <div class="app-footer white">
    <v-text-field
      @keyup.enter="sendMessage"
      outlined
      auto-grow
      flat
      label="Type Message"
      v-model="newMessage"
      rows="2"
      row-height="10"
    />

    <div class="d-flex justify-end mt-n3 px-3">
      <v-btn class="mx-2" fab dark small color="primary">
        <v-icon dark>mdi-send</v-icon>
      </v-btn>
      <v-btn class="mx-2" fab dark outlined small color="primary">
        <v-icon class="rotate-90" dark>mdi-attachment</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      newMessage: "",
    };
  },
  methods: {
    sendMessage() {
      // this.chats.push({
      //   text: this.newMessage,
      //   textTwo: "Hi, I'm Jason Oner. Your imaginary friend."
      // })

      this.singleChatInfo.push({
        textTwo: this.newMessage,
        avatar: require("@/assets/images/faces/2.jpg"),
        text: "Hi, I'm Jason Oner. Your imaginary friend.",
      });
      this.newMessage = "";
    },
  },
  computed: {
    ...mapGetters(["singleChatInfo"]),
  },
};
</script>
<style lang="">
</style>