<template>
  <div class="app-content relative" v-if="singleChatInfo !== null">
    <Header />

    <TalkArea />

    <InputArea />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./comp/Header.vue";
import TalkArea from "./comp/TalkArea.vue";
import InputArea from "./comp/InputArea.vue";
export default {
  components: {
    Header,
    TalkArea,
    InputArea,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters(["singleChatInfo"]),
  },
};
</script>
<style lang="">
</style>