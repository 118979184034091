<template>
  <div class="app-header white">
    <div class="d-flex justify-space-between align-center">
      <div class="d-flex align-center">
        <div class="app-icon mr-2" @click="appToggle">
          <v-icon color> mdi-menu-open </v-icon>
        </div>
        <div class="d-flex align-center">
          <v-avatar size="41" class="mr-2">
            <img src="@/assets/images/faces/13.jpg" alt="" />
          </v-avatar>
          <div>
            <p class="ma-0 body-1 font-weight-medium">Jason Oner</p>
          </div>
        </div>
      </div>
      <div>
        <v-btn icon color="primary">
          <v-icon>mdi-phone</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    appToggle: function () {
      this.isOpen = !this.isOpen;
      this.isBlock = !this.isBlock;
    },
  },
};
</script>
<style lang="">
</style>