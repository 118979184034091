<template>
  <v-list two-line subheader v-if="singleChatInfo !== null">
    <v-subheader class="font-weight-bold grey--text"> Recent chat </v-subheader>
    <v-list-item v-for="(item, index) in itemsSb" :key="index" class="">
      <v-list-item-avatar>
        <v-img :src="item.avatar" />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
        <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon class="align-self-center">
        <v-icon class="body-1" :color="item.active ? 'success' : 'grey'">
          mdi-record
        </v-icon>
      </v-list-item-icon>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      itemsSb: [
        {
          active: true,
          title: "Jason Oner",
          avatar: require("@/assets/images/faces/13.jpg"),
          subtitle: "<span class=''>3 Oct, 2018</span>",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["singleChatInfo"]),
  },
};
</script>
<style lang="">
</style>