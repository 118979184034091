<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll app-sidebar-scroll"
    style="height: 100%"
  >
    <div class="app-sidebar-body">
      <RecentChat />

      <!-- <v-divider /> -->

      <Contacts :patientData="patientData" />
    </div>
  </vue-perfect-scrollbar>
</template>
<script>
import RecentChat from "./comp/RecentChat.vue";
import Contacts from "./comp/Contacts.vue";
export default {
  components: {
    RecentChat,
    Contacts,
  },
  props: {
    patientData: {
      type: [Object, Array],
    },
  },
  methods: {},
};
</script>
<style lang="">
</style>