<template>
  <div class="app-content-wrapper apps-wrapper">
    <div :class="{ open: isBlock }" class="app-overlay" @click="overlayApp" />
    <div :class="{ open: isOpen }" class="app-sidebar bg-white">
      <!-- <Search /> -->

      <!-- clinic -->
      <div class="clinicBox mt-5">
        <SelectBoxClinic
          selectStyleWidth="width: 100%"
          @updateSelect="updateSelectClinic"
        />
      </div>

      <Side :patientData="patientData" />
    </div>

    <ChatMain />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Search from "./comp/Search.vue";
import SelectBoxClinic from "@/components/commonV2/ui/SelectBoxClinic.vue";
import Side from "./comp/side/Side.vue";
import ChatMain from "./comp/main/ChatMain.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

const formatService = require("@/utils/format.js");
const modelService = require("@/utils/dataModel");

export default {
  name: "Chat",
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Chat",
  },
  components: {
    VuePerfectScrollbar,
    Search,
    SelectBoxClinic,
    Side,
    ChatMain,
  },
  // data: () => ({
  //   isBlock: false,
  //   isOpen: true,
  // }),
  data() {
    return {
      isBlock: false,
      isOpen: true,
      patientData: [],
      propsDate: [this.$moment().subtract(30, "year"), this.$moment()],
      reqData: modelService.reqDataModel(),
    };
  },
  created() {
    this.getData();
  },
  methods: {
    overlayApp: function () {
      this.isOpen = !this.isOpen;
      this.isBlock = !this.isBlock;
    },
    updateSelectClinic(e) {
      this.reqData.hospital_id = e === "" ? 0 : e;
      this.getData();
    },
    /**** 데이터읽기 ****/
    async getData() {
      this.isLoading = true;
      this.patientData = [];
      const $h = this.$helper;
      this.reqData.endpoint = "patient";
      this.reqData.fromDay = $h.changeDateToString(this.propsDate[0], false);
      this.reqData.toDay = $h.changeDateToString(this.propsDate[1], false);
      await this.getDataQuery(this.reqData).then((resP) => {
        let res = resP.data;
        if (0 < res.length) {
          res = res.map((item, index) => ({
            ...item,
            no: index + 1,
            genderShort: $h.changeGenderShortForDp(item.gender),
            age: $h.changeDOBToAgeForDp(item.dob),
            regDateLabel: $h.changeDateToStringForDp(item.regDate, true),
            updDateLabel: $h.changeDateToStringForDp(item.updDate),
            avatar: $h.makeUserAvatarImg(
              item.userPhotoImg,
              $h.isPhotoChecker(item.userPhotoImg),
              item.gender
            ),
          }));
        }
        console.log("this.patientData", res);
        this.patientData = res;
        this.isLoading = false;
      });
    },
    ...mapActions(["getDataQuery"]),
  },
};
</script>
<style lang="scss">
// @import '@/assets/scss/egret-design-system/variables/_variables.scss';

.app-content-wrapper {
  position: relative;
  display: flex;
  overflow: hidden;
  height: calc(100vh - 127px);

  .app-overlay {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
    z-index: 2;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      &.open {
        display: block;
        transition: all 0.3s ease-in;
      }
    }
  }
  .app-sidebar {
    width: 280px;
    position: relative;
    transition: all 0.3s ease-in;
    @media only screen and (max-width: 959px) {
      position: absolute;
      left: -340px;
      z-index: 5;
      height: calc(100vh - 120px);
      transition: all 0.3s ease-in;
      &.open {
        left: 0;
      }
    }
    .app-sidebar-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-sidebar-body {
      // overflow-y: scroll;
      // height: 100%;
      // padding-top: 3.375rem;
      width: 280px;
    }
  }

  .app-content {
    position: relative;
    width: calc(100% - 280px);
    @media only screen and (max-width: 959px) {
      width: 100%;
    }
    // margin-left: 30px;
    .app-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .app-body {
      flex: 1 1 auto;
      padding-top: 3.375rem;
      // overflow-y: scroll;
      height: calc(100% - 130px);
    }
    .app-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 130px;
    }
  }
}
.app-icon {
  display: none;
  @media only screen and (max-width: 959px) {
    display: block;
  }
}
</style>
