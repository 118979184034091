<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="rtl-ps-none ps scroll"
    style="height: calc(100% - 130px)"
  >
    <div class="app-body mt-12 px-4">
      <!-- <div>
        <div class="d-flex align-center mb-5">
          <v-avatar size="41" class="mr-4">
            <img src="@/assets/images/faces/13.jpg" alt="" />
          </v-avatar>
          <p class="ma-0 bg-primary white--text py-2 px-3 rounded">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <div class="d-flex flex-reverse align-center mb-3 justify-end">
          <p class="ma-0 white text-gray-900 py-2 px-3 rounded">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <div class="d-flex align-center my-3">
          <v-divider></v-divider>
          <div class="px-3">01 May 20</div>
          <v-divider></v-divider>
        </div>
      </div> -->
      <div v-for="(chatList, index) in mySingleChatInfo" :key="index">
        <div class="d-flex align-center mb-5">
          <v-avatar size="41" class="mr-4">
            <img :src="chatList.avatar" alt="" />
          </v-avatar>
          <p class="ma-0 bg-primary white--text py-2 px-3 rounded">
            {{ chatList.text }}
          </p>
        </div>
        <div class="d-flex flex-reverse align-center mb-3 justify-end">
          <p class="ma-0 white black--text py-2 px-3 rounded">
            {{ chatList.textTwo }}
          </p>
        </div>
        <div class="d-flex align-center my-3">
          <v-divider></v-divider>
          <div class="px-3">01 May 20</div>
          <v-divider></v-divider>
        </div>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  watch: {
    singleChatInfo(val) {
      this.mySingleChatInfo = val;
    },
  },
  data() {
    return {
      mySingleChatInfo: [],
    };
  },
  computed: {
    ...mapGetters(["singleChatInfo"]),
  },
};
</script>
<style lang="">
</style>