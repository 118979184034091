<template>
  <div class="app-sidebar-header white shadow">
    <v-text-field
      hide-details="auto"
      class="d-md-flex ma-0 pa-0 d-sm-hidden"
      label="Search"
      outlined
      dense
      prepend-inner-icon="mdi-magnify"
    />
  </div>
</template>
<script>
export default {};
</script>
<style lang="">
</style>